var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticStyle:{"background":"#aaa"},attrs:{"elevation":"0","border":"1px solid #aaa"}},[_c('v-carousel',{attrs:{"height":"500","cycle":"","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.slides.slide),function(slide,index){return _c('v-carousel-item',{key:index},[_c('v-card',{staticClass:"hover",attrs:{"color":"grey lighten","height":"100%"},on:{"click":function($event){return _vm.route(slide.url)}}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{staticStyle:{"height":"100%","background":"#0d4474"},attrs:{"md":"4","cols":"12"}},[_c('v-row',{staticClass:"fill-height px-10",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('div',{staticStyle:{"color":"#fff","font-weight":"bold","padding-right":"50px","padding-left":"50px"}},[_c('div',{staticClass:"text-center"},[(slide.title)?_c('h2',[_vm._v(_vm._s(slide.title))]):_vm._e(),(slide.summary)?_c('h3',{staticClass:"mt-5",staticStyle:{"color":"#fff","font-size":"14px"}},[_vm._v(" "+_vm._s(slide.summary)+" ")]):_vm._e(),(slide.url)?_c('v-btn',{staticClass:"mt-12",attrs:{"aria-label":"Read More button"},on:{"click":function($event){return _vm.route(slide.url)}}},[_vm._v("Read more")]):_vm._e()],1)])])],1),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"md":"8","cols":"12"}},[(slide.image && slide.image.formats)?_c('v-img',{attrs:{"aria-label":"ICJIA Main Splash Image","src":_vm.getImageURL(_vm.$myApp.config.api.base + slide.image.url),"lazy-src":_vm.getImageURL(
                    _vm.$myApp.config.api.base +
                      slide.image.formats.thumbnail.url,
                    0,
                    0,
                    1
                  ),"aspect-ratio":"1.7","height":"500"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","aria-label":"Progress bar: Loading","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }