<template>
  <ApolloQuery
    :query="GET_HOME"
    notifyOnNetworkStatusChange
    :variables="{ now, eventLimit, postLimit }"
    @result="afterFetch"
  >
    <template slot-scope="{ result }">
      <div v-if="isLoading(result.loading)">
        <Loader></Loader>
      </div>

      <div
        v-if="!isLoading(result.loading) && !result.error"
        style="background: #fff"
      >
        <!-- START: home components -->

        <HomeSlider
          :slides="result.data.home.slider"
          v-if="result.data.home.slider"
        ></HomeSlider>

        <HomeBoxes style="margin-top: -10px"></HomeBoxes>
        <!-- <h1 class="aria-hidden">Welcome to the ICJIA Intranet</h1> -->

        <v-container fluid style="">
          <v-row>
            <v-col>
              <v-sheet class="px-2 py-2" elevation="0" style="">
                <v-container fluid>
                  <v-container fluid style="margin: 0; padding: 0">
                    <div style="background: #f3f5f7" class="px-2 py-2">
                      <v-row no-gutters>
                        <v-col>
                          <h2>Recent ICJIA Documents</h2>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>

                  <v-row style="margin-top: -5px">
                    <v-col v-if="documents.length">
                      <DocumentTable
                        :documents="filteredDocuments"
                        :hideFooter="false"
                        :hideSearch="false"
                        chipLabel="Updated!"
                        style="border: 0px solid #eee"
                        :itemsPerPage="15"
                      ></DocumentTable>
                      <div
                        class="text-right pr-2 hidden-sm-and-down"
                        style="font-size: 12px; margin-top: -5px"
                      >
                        <router-link to="/documents/">Click here</router-link>
                        for the full listing of ICJIA documents.
                      </div>
                    </v-col>
                    <v-col v-else>
                      <div class="text-center">
                        <h2>No documents found</h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>

        <v-container
          fluid
          style="margin-top: 20px; border-top: 1px solid #ddd"
          class="markdown-body"
        >
          <v-row>
            <v-col sm="12" md="6" cols="12" class="child">
              <v-sheet class="" elevation="0" style="">
                <v-container>
                  <v-container
                    fluid
                    style="margin: 0; padding: 0; margin-top: -25px"
                  >
                    <div style="background: #f3f5f7" class="">
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex">
                          <h2
                            style="border-bottom: 0px; padding: 8px; margin: 0"
                          >
                            Latest News
                          </h2>
                          <v-spacer></v-spacer>
                          <div
                            class="pt-3 pr-5 hidden-sm-and-down"
                            style="font-size: 12px"
                          >
                            <!-- <router-link to="/"
                              >News Archive&nbsp;&raquo;</router-link
                            > -->
                            <v-btn to="/news/" x-small outlined
                              >All news&nbsp;&raquo;</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                  <v-row class="mt-2">
                    <v-col>
                      <HomePosts
                        :posts="result.data.posts"
                        v-if="result.data.posts"
                      ></HomePosts>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-col>
            <v-col
              sm="12"
              md="6"
              cols="12"
              class="child"
              style="border-left: 1px solid #ddd; margin-top: -12px"
            >
              <v-sheet class="px-2" elevation="0" style="">
                <v-container fluid style="margin: 0; padding: 0">
                  <div style="background: #f3f5f7" class="">
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex">
                        <h2 style="border-bottom: 0px; padding: 8px; margin: 0">
                          Latest Events
                        </h2>
                        <v-spacer></v-spacer>
                        <div
                          class="pt-3 pr-5 hidden-sm-and-down"
                          style="font-size: 12px"
                        >
                          <!-- <router-link to="/"
                              >News Archive&nbsp;&raquo;</router-link
                            > -->
                          <v-btn to="/events/" x-small outlined
                            >Calendar&nbsp;&raquo;</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
                <HomeEvents
                  class="mt-4"
                  :events="mergedEvents"
                  v-if="result.data.events && result.data.eventRange"
                ></HomeEvents>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>

        <v-container
          fluid
          style="margin-top: 20px; border-top: 1px solid #ddd"
          class=""
        >
          <v-row>
            <v-col sm="12" cols="12" md="6" class="child">
              <v-sheet class="px-2 py-2" elevation="0">
                <v-container fluid style="margin: 0; padding: 0">
                  <div style="background: #f3f5f7" class="px-3 py-3">
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex">
                        <h2>Recent Articles</h2>
                        <v-spacer></v-spacer>
                        <div class="pt-1 pr-5 hidden-sm-and-down">
                          <!-- <router-link to="/"
                              >News Archive&nbsp;&raquo;</router-link
                            > -->
                          <v-btn
                            small
                            href="https://icjia.illinois.gov/researchhub/articles/"
                            target="_blank"
                            outlined
                            >All Articles<v-icon right>open_in_new</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
                <ResearchArticles :limit="4" class="mt-2"></ResearchArticles>
                <div class="py-2 text-center reduce-85">
                  Visit
                  <a
                    href="https://icjia.illinois.gov/researchhub"
                    target="_blank"
                    >ICJIA's ResearchHub</a
                  >
                  for more articles, datasets, and applications.
                </div>
              </v-sheet>
            </v-col>
            <v-col sm="12" cols="12" md="6" class="child">
              <v-sheet class="px-2 py-2" elevation="0">
                <v-container fluid style="margin: 0; padding: 0">
                  <div style="background: #f3f5f7" class="px-3 py-3">
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex">
                        <h2>Recent Applications</h2>
                        <v-spacer></v-spacer>
                        <div class="pt-1 pr-5 hidden-sm-and-down">
                          <!-- <router-link to="/"
                              >News Archive&nbsp;&raquo;</router-link
                            > -->
                          <v-btn
                            small
                            href="https://icjia.illinois.gov/researchhub/apps/"
                            target="_blank"
                            outlined
                            >All Applications<v-icon right>open_in_new</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
                <ResearchApplications
                  :limit="5"
                  class="mt-2"
                ></ResearchApplications>
                <div class="py-2 text-center reduce-85">
                  Visit
                  <a
                    href="https://icjia.illinois.gov/researchhub"
                    target="_blank"
                    >ICJIA's ResearchHub</a
                  >
                  for more articles, datasets, and applications.
                </div>
              </v-sheet>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" md="6" class="text-center">
              <v-sheet class="px-2 py-2" elevation="0" style="">
                <v-container fluid style="margin: 0; padding: 0" class="mb-3">
                  <div style="background: #f3f5f7" class="px-3 py-3">
                    <v-row no-gutters>
                      <v-col>
                        <h2>ICJIA on Facebook</h2>
                      </v-col>
                      <v-col class="text-right mt-1">
                        <v-btn small @click="facebookKey++"
                          >Refresh <v-icon right small>refresh</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-container>

                <Facebook :key="facebookKey"></Facebook>
              </v-sheet>
           
            </v-col>
            <v-col cols="12" md="6">
              <v-sheet class="px-2 py-2" elevation="0" style="">
                <v-container fluid style="margin: 0; padding: 0">
                  <div style="background: #f3f5f7" class="px-3 py-3">
                    <v-row no-gutters>
                      <v-col>
                        <h2>ICJIA on Twitter</h2>
                      </v-col>
                      <v-col class="text-right mt-1">
                        <v-btn small @click="twitterKey++"
                          >Refresh <v-icon right small>refresh</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
                <Twitter :key="twitterKey" :tweetLimit="3"></Twitter>
              </v-sheet>
            </v-col>
          </v-row> -->
        </v-container>

        <!-- END: home components -->
      </div>
      <div v-if="result.error" class="text-center error apollo">
        {{ result.error }}
        <div class="text-center my-10 py-5">
          <a href="/login">PLEASE CLICK HERE TO LOGIN AGAIN</a>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>
<script>
import { GET_HOME } from "@/graphql/queries/home.js";
// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import tz from "moment-timezone";
// eslint-disable-next-line no-unused-vars
//import { Tweet, Moment, Timeline } from "vue-tweet-embed";
import { nanoid } from "nanoid";
// eslint-disable-next-line no-unused-vars
import { addButtonText, fixBlankTableHeadings } from "@/a11y";

export default {
  name: "Home",
  components: {},
  computed: {
    name() {
      return this.data;
    },
  },
  created() {
    this.now = moment().tz(this.$myApp.config.timezone).format("YYYY-MM-DD");
  },
  mounted() {
    //console.log(this.$myApp.config.timezone);
  },
  data() {
    return {
      GET_HOME,
      now: null,
      documents: null,
      filteredDocuments: null,
      twitterKey: 0,
      facebookKey: 0,
      nanoid,
      mergedEvents: () => [],
      eventLimit: this.$myApp.config.home.eventLimit,
      postLimit: this.$myApp.config.home.postLimit,
    };
  },
  methods: {
    // fixA11Y() {
    //   addButtonText("v-data-table__expand-icon", "Show/Hide information");
    //   fixBlankTableHeadings();
    // },\
    // fixTable() {
    //   console.log("fix table here");
    // },
    isLoading(loading) {
      // eslint-disable-next-line no-undef
      loading ? window.NProgress.start() : window.NProgress.done();
      return loading ? true : false;
    },

    afterFetch(result) {
      if (result.data && result.data.events && result.data.eventRange) {
        let mergedEvents = [...result.data.events, ...result.data.eventRange];
        mergedEvents = _.sortBy(mergedEvents, (o) => o.start);
        this.mergedEvents = mergedEvents.slice(0, this.eventLimit);
      }

      if (result.data && result.data.documents) {
        this.documents = result.data.documents;
        this.documents = this.documents.map((d) => ({
          ...d,
          show: false,
        }));
        this.filteredDocuments = this.documents;
      }
    },
  },
};
</script>

<style>
.hover {
  cursor: pointer;
}
.card:hover {
  box-shadow: 0px 0px 15px #000000;
  z-index: 2;
  -webkit-transition: all 100ms ease-in;
  -webkit-transform: scale(1.01);
  -ms-transition: all 100ms ease-in;
  -ms-transform: scale(1.01);
  -moz-transition: all 100ms ease-in;
  -moz-transform: scale(1.01);
  transition: all 100ms ease-in;
  transform: scale(1.01);
  cursor: pointer;
  background: #fafafa;
}
</style>
