var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loading)?_c('div',_vm._l((_vm.apps.length),function(app,index){return _c('v-card',{key:index,staticClass:"mb-5 px-3 py-3 hover info-card",staticStyle:{"border":"1px #eee solid"},attrs:{"elevation":"1","ripple":false,"data-aos":"fade-up","data-aos-offset":"100","data-aos-delay":"0"},on:{"click":function($event){return _vm.routeTo(_vm.apps[app - 1]['url'])}}},[_c('div',{},[_c('v-img',{staticClass:"white--text align-end",attrs:{"aria-label":"ReseearchHub Application image","height":"225px","src":_vm.apps[app - 1]['image']}}),_c('h2',{staticClass:"px-5 pt-5"},[_vm._v(" "+_vm._s(_vm.apps[app - 1]["title"])+" ")]),_c('div',{staticClass:"px-4 pt-3"},[_vm._v(" By "+_vm._s(_vm.apps[app - 1]["contributors"][0]["title"])+" ")]),_c('v-card-subtitle',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm._f("format")(_vm.apps[app - 1]["date"])))]),_c('v-card-text',{staticClass:"text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.apps[app - 1]["description"])+" ")])],1),_c('v-card-actions',{staticClass:"py-5"},[(
            _vm.apps[app - 1]['articles'].length ||
            _vm.apps[app - 1]['datasets'].length
          )?_c('v-btn',{staticStyle:{"color":"#075e60"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.apps[app - 1]['show'] = !_vm.apps[app - 1]['show']}}},[_vm._v("Show Related "),(!_vm.apps[app - 1]['show'])?_c('v-icon',[_vm._v("expand_more")]):_c('v-icon',[_vm._v("expand_less")])],1):_vm._e(),_c('v-spacer')],1),_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.apps[app - 1]['show']),expression:"apps[app - 1]['show']"}]},[(
              _vm.apps[app - 1]['articles'].length ||
              _vm.apps[app - 1]['datasets'].length
            )?_c('div',{staticStyle:{"background":"#eee"}},[_c('v-card-text',[(_vm.apps[app - 1]['articles'].length)?_c('div',{staticClass:"pb-3 pl-1"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Articles:")])]),_vm._l((_vm.apps[app - 1]['articles']),function(article){return _c('ul',{key:article.title,staticClass:"ml-2 related"},[_c('li',[_c('a',{staticClass:"relatedLink",staticStyle:{"background":"none"},attrs:{"href":`https://icjia.illinois.gov/researchhub/articles/${article.slug}`,"target":"_blank"}},[_vm._v(_vm._s(article.title))])])])})],2):_vm._e(),(_vm.apps[app - 1]['datasets'].length)?_c('div',{staticClass:"pb-3 pl-1"},[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Datasets:")])]),_vm._l((_vm.apps[app - 1]['datasets']),function(dataset){return _c('ul',{key:dataset.title,staticClass:"ml-2 related"},[_c('li',[_c('a',{staticClass:"relatedLink",staticStyle:{"background":"none"},attrs:{"href":`https://icjia.illinois.gov/researchhub/datasets/${dataset.slug}`,"target":"_blank"}},[_vm._v(_vm._s(dataset.title))])])])})],2):_vm._e()])],1):_vm._e()])])],1)}),1):_c('div',[_c('Loader')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }