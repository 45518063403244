<template>
  <div>
    <v-sheet color="grey lighten-3" class="px-3 py-3">
      <div
        style="
          font-weight: bold;
          border-bottom: 1px solid #ccc;
          padding-bottom: 5px;
          margin-bottom: 15px;
        "
      >
        {{ heading }}
      </div>

      <ul class="mt-2">
        <span
          v-for="(document, index) in documents"
          :key="`eventDoc-${index}`"
          class="download-link"
        >
          <li
            v-if="document.file"
            class="hover"
            @click.stop.prevent="download(document.file)"
          >
            {{ document.title
            }}<v-icon class="ml-2" small>cloud_download</v-icon>
          </li>
          <li
            v-else
            class="hover"
            @click.stop.prevent="goToExternal(document.externalURL)"
          >
            {{ document.title }}&nbsp;<v-icon x-small>open_in_new</v-icon>
          </li>
        </span>
      </ul>
    </v-sheet>
  </div>
</template>

<script>
export default {
  methods: {
    goToExternal(url) {
      console.log("external_click: ", url);
      //window.plausible("external_click", { props: { url: url } });
      if (url.indexOf("://") > 0 || url.indexOf("//") === 0) {
        window.open(url);
        console.log("absolute: ", url);
      } else {
        this.$router.push(url);
        console.log("relative: ", url);
      }
    },
    download(file) {
      let download = `${this.$myApp.config.api.base}${file.url}`;
      // console.log("document_download: ", file.url);
      // window.plausible("document_download", { props: { file: file.url } });
      if (file.ext === ".pdf") {
        window.open(download);
      } else {
        location.href = download;
      }
    },
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    heading: {
      type: String,
      default: "Related",
    },
  },
};
</script>
