var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"read-progress-container"},[_c('span',{staticClass:"read-progress-bar",class:{ 'with-shadow': _vm.shadow },style:({
      width: _vm.readProgress + '%',
      color: _vm.color,
      height: _vm.height,
      opacity: _vm.opacity,
      backgroundColor: _vm.color,
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }